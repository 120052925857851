import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "gatsby";
import { FaCircle } from "react-icons/fa";

import requests from "../../../services/requests";
import LogoIcon from "../../../assets/svgs/logo-colorful.inline.svg";
import { colors } from "../../../assets/colors";
import { Tag, Tag2 } from "../../../components/TrackProcess/styles";
import BookmarkIcon from "../../../components/Icons/BookmarkIcon";
import Card from "../../../components/Cards";

const SubmenuDetail = ({ code, tags, process, prevPath }) => {
    const { brazilIcon } = useStaticQuery(graphql`
        query {
            brazilIcon: file(relativePath: { eq: "brazil.png" }) {
                childImageSharp {
                    fixed(height: 19, width: 25) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            flagSaveIcon: file(relativePath: { eq: "flagSave.png" }) {
                childImageSharp {
                    fixed(height: 40, width: 40) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);

    let [isPriority, setIsPriority] = useState(false);
    let [isSavingPriority, setSavingIsPriority] = useState(false);

    const checkIsPriority = () => {
        requests.checkProcessPriority(process.identifier).then(data => {
            setIsPriority(data.isFavourite);
        });
    };

    const savePriority = () => {
        setSavingIsPriority(true);
        requests.saveProcessPriority(process.identifier, !isPriority).then(data => {
            setIsPriority(!isPriority);

            setSavingIsPriority(false);
        });
    };

    useEffect(() => {
        if (process && process.code) {
            checkIsPriority();
        }
    }, [process]);

    return (
        <Card className="justify-between flex flex-wrap lg:px-4 lg:py-3">
            <div className="xs:w-full lg:w-2/5 flex items-center">
                <Link to={prevPath || `/processos/`}>
                    <IoIosArrowBack className="text-roxo_oficial sm:h-14 sm:w-14  md:h-6 md:w-6 xs:h-10 xs:w-10" />
                </Link>
                <div className="text-white ml-5">
                    {isSavingPriority ? (
                        <div className="flex flex-col items-center">
                            <ClipLoader size={20} loading={isSavingPriority} />
                        </div>
                    ) : (
                        <BookmarkIcon
                            className={`h-8 w-8 p-2 fill-current rounded-full cursor-pointer ${
                                isPriority ? (process.returnOrigin ? "bg-rosa_oficial" : "bg-roxo_oficial") : "bg-C6"
                            }`}
                            onClick={savePriority}
                            isPriority={isPriority}
                            identifier={process?.identifier}
                        />
                    )}
                </div>
                <h2 className="ml-3">
                    {code}
                    {process && (
                        <>
                            {process.channel?.toLowerCase() === "verde" && (
                                <FaCircle
                                    style={{ display: "inline" }}
                                    className="d-inline ml-2 mb-1"
                                    size={15}
                                    color={colors.canal_verde}
                                />
                            )}
                            {process.channel?.toLowerCase() === "amarelo" && (
                                <FaCircle
                                    style={{ display: "inline" }}
                                    className="d-inline ml-2 mb-1"
                                    size={15}
                                    color={colors.canal_amarelo}
                                />
                            )}
                            {process.channel?.toLowerCase() === "vermelho" && (
                                <FaCircle
                                    style={{ display: "inline" }}
                                    className="d-inline ml-2 mb-1"
                                    size={15}
                                    color={colors.canal_vermelho}
                                />
                            )}
                            {process.channel?.toLowerCase() === "cinza" && (
                                <FaCircle
                                    style={{ display: "inline" }}
                                    className="d-inline ml-2 mb-1"
                                    size={15}
                                    color={colors.C7}
                                />
                            )}
                        </>
                    )}
                </h2>
            </div>
            <div className="flex  lg:w-3/5  items-center justify-items-center self-center border-l border-c6 pl-4 flex-wrap">
                <div className="mr-4">
                    {process.returnOrigin ? (
                        <span
                            className="w-8 text-xl font-black text-rosa_oficial"
                            title={
                                process.originDescription?.toLowerCase() === "gestao"
                                    ? "Processo de Gestão"
                                    : "Processo Próprio"
                            }
                        >
                            {process.originDescription?.toLowerCase() === "gestao" ? "G" : "P"}
                        </span>
                    ) : (
                        <LogoIcon className="w-8" />
                    )}
                </div>{" "}
                <div className="mr-4">
                    <Img className="mt-2" fixed={brazilIcon.childImageSharp.fixed} />
                </div>
                {tags &&
                    tags.map((tag, index) => {
                        return (
                            <Tag key={index} className=" xs:mt-4 lg:mt-0">
                                {tag}
                            </Tag>
                        );
                    })}
                {process &&
                    (process.returnOrigin ? (
                        <>
                            {process.clientInternalCode && (
                                <Tag2 key={process.clientInternalCode}>{process.clientInternalCode}</Tag2>
                            )}
                            {process.currentStageModality && (
                                <Tag2 key={process.currentStageModality}>{process.currentStageModality}</Tag2>
                            )}
                            {process.originDescription && (
                                <Tag2 key={process.originDescription}>
                                    {process.originDescription?.toLowerCase() === "gestao" ? "Gestão" : "Própria"}
                                </Tag2>
                            )}
                            {process.channeltransportModality && (
                                <Tag2 key={process.channeltransportModality}>{process.transportModality}</Tag2>
                            )}
                            {process.channel && <Tag2 key={process.channel}>{process.channel}</Tag2>}
                            <Tag2 key="importação">Importação</Tag2>
                        </>
                    ) : (
                        <>
                            {process.clientInternalCode && (
                                <Tag key={process.clientInternalCode}>{process.clientInternalCode}</Tag>
                            )}
                            {process.currentStageModality && (
                                <Tag key={process.currentStageModality}>{process.currentStageModality}</Tag>
                            )}
                            {process.modality && <Tag key={process.modality}>{process.modality}</Tag>}
                            {process.channeltransportModality && (
                                <Tag key={process.channeltransportModality}>{process.transportModality}</Tag>
                            )}
                            {process.channel && <Tag key={process.channel}>{process.channel}</Tag>}
                            <Tag key="importação">Importação</Tag>
                        </>
                    ))}
            </div>
        </Card>
    );
};
export default SubmenuDetail;
