import React from "react";
import classnames from "classnames";
import "./style.css";

interface Props {
    children: React.ReactNode;
    className: string;
    id?: string;
    map?: string;
}

const Card: React.FC<Props> = ({ children, className, id, map }: Props) => (
    <div id={id} className={classnames(`bg-white ${map ? "" : "p-4"}`, className)}>
        {children}
    </div>
);

export default Card;
