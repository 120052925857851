import React, { useEffect, useRef } from "react";

import Icon from "../../../assets/svgs/bookmark.inline.svg";
import ReactTooltip from "react-tooltip";

import Container from "./styles";

function BookmarkIcon({ identifier, isPriority, ...props }) {
    const isPriorityRef = useRef();
    useEffect(() => {
        if (isPriorityRef.current && isPriorityRef.current !== isPriority) {
            ReactTooltip.rebuild();
        }
        isPriorityRef.current = isPriority;
    }, [isPriority]);

    return identifier ? (
        <>
            <Container data-delay-show="500" data-tip data-for={`tip${identifier}`}>
                <Icon {...props} />
            </Container>
            <ReactTooltip id={`tip${identifier}`} effect="solid" type="dark" delayShow={500}>
                {isPriority ? (
                    <span>
                        Aperte neste ícone para <br />
                        retirar das prioridades
                    </span>
                ) : (
                    <span>
                        Aperte neste ícone para <br />
                        adicionar às prioridades
                    </span>
                )}
            </ReactTooltip>
        </>
    ) : (
        <div />
    );
}

export default BookmarkIcon;
