export const colors = {
    roxo_oficial: "#422C76",
    roxo_oficial_light: "#EAE1FF",
    roxo_1: "#634A98",
    roxo_2: "#866ABB",
    roxo_3: "#AA8BE0",
    roxo_4: "#D0B0FD",
    roxo_5: "#FAD8FF",
    canal_verde: "#70D499",
    canal_amarelo: "#F3D783",
    canal_vermelho: "#EC5D66",
    canal_cinza: "#4d4d4d",

    rosa_oficial: "#E52359",
    red: "#E65250",
    C1: "#F5F5F5",
    C2: "#DCDCDC",
    C3: "#C8C8C8",
    C4: "#A7A7A7",
    C5: "#878787",
    C6: "#6A6A6A",
    C7: "#707070",
};
