import styled from "styled-components";

export const Tag = styled.span`
    padding: 2px 6px;
    color: #422c76;
    border: 1px solid #422c76;
    border-radius: 5px;
    margin-right: 12px;
    white-space: nowrap;
`;

export const Tag2 = styled.span`
    padding: 2px 6px;
    /*background-color: #E52359;*/
    color: #e52359;
    border: 1px solid #e52359;
    border-radius: 5px;
    margin-right: 12px;
    white-space: nowrap;
`;
